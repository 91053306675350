import React, { useContext } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './CTACampaignCard.module.scss';
import { TrackingContext } from '../StreamField/StreamField';
import { trackCtaClickStartpage } from '../../utils/datalayer';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

const CTACampaignCard = ({
    image,
    preamble,
    title,
    text,
    link,
    whiteBackground,
}) => {
    const isStartpage = useContext(TrackingContext) !== null;

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const classes = classNames(styles['CTACampaignCard'], {
        [styles['CTACampaignCard--WhiteBackground']]: whiteBackground,
    });

    return (
        <div className={classes} ref={ref}>
            <div className={styles['CTACampaignCard__Background']} />
            <div
                className={classNames(
                    styles['CTACampaignCard__ImageContainer'],
                    {
                        [styles['CTACampaignCard__ImageContainer--isVisible']]:
                            inView,
                    }
                )}>
                {image.renditions && (
                    <div className={styles['CTACampaignCard__ImageWrapper']}>
                        <img
                            className={styles['CTACampaignCard__Image']}
                            sizes="100vw"
                            srcSet={`${image.renditions.large.src} 1200w,
                                    ${image.renditions.medium.src} 600w`}
                            src={image.renditions.small.src}
                            alt={`${image.alt}`}
                        />
                    </div>
                )}
                <div className={styles['CTACampaignCard__Container']}>
                    <div className={styles['CTACampaignCard__TextContainer']}>
                        <div className={styles['CTACampaignCard__TextWrapper']}>
                            <p className={styles['CTACampaignCard__Preamble']}>
                                {preamble}
                            </p>
                            <h2 className={styles['CTACampaignCard__Title']}>
                                {title}
                            </h2>
                            <p className={styles['CTACampaignCard__Text']}>
                                {text}
                            </p>
                            {link && (
                                <div
                                    className={
                                        styles['CTACampaignCard__LinkContainer']
                                    }>
                                    <Button
                                        href={link.href}
                                        text={link.label}
                                        type="primary"
                                        icon="arrow"
                                        iconSize="large"
                                        iconColor="white"
                                        buttonColor="pink"
                                        onClick={() => {
                                            if (isStartpage) {
                                                trackCtaClickStartpage(
                                                    isStartpage
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CTACampaignCard.propTypes = {};

export default CTACampaignCard;
